/*************************************
// Algolia Search component style
***************************************/
:root {
	
	--aa-input-border-color-rgb: 255, 255, 255;
	--aa-description-highlight-background-color-rgb: 225, 225, 225;
	--aa-icon-size: 20px;
	--aa-panel-border-color-rgb: 200, 0, 0;
	--color: rgb(179, 173, 214);
	--aa-primary-color-rgb: 200, 200, 200;
	--aa-selected-color-rgb: 210, 210, 210;
	--aa-icon-color-rgb: 200, 200, 200;
	--aa-panel-shadow: 0 0 0 1px rgba(38, 35, 35, .1),
    0 6px 16px -4px rgba(38, 35, 35, .15);
  
}
.aa-ItemContentBody h4{
	font-family: inherit;
	font-size: 1.25rem;
	margin-bottom: 5px;
  word-break:break-word;
  white-space: normal;
}
.aa-ItemContentBody h4:hover {
	text-decoration: none;
}
.aa-Item{
	border-bottom: 1px solid #ccc;
}
.aa-Item:last-child{
	border-bottom: none;
}
.aa-ItemContentDescription{
	padding: 1px 0;
}
.aa-ItemContentDescription p {
	font-size: 0.8rem;
}
.aa-ItemContentDescription h5 {
	font-size: 1rem;
}
.aa-ItemIcon--picture{
	text-align: center;
}
.aa-ItemIcon--picture img{
	height: 100%;
}

.aa-Form:focus-within {
  border: none;
	text-decoration: none;
	color: inherit;
  box-shadow: none; 
  outline: currentColor none medium;
}
.main-search-input input {
	width: 98%;
	border-bottom: 1px solid;
	border-bottom-color: rgba(var(--aa-primary-color-rgb), 1);
}
.aa-InputWrapper {
  width: 100%;
}
@media (max-width: 768px) {
	.aa-InputWrapper input,
  .aa-InputWrapper input:focus {
    max-width: 100%;
    border-radius: 4px 40px 40px 4px;
    border-bottom: 1px solid;
	  border-bottom-color: rgba(var(--aa-primary-color-rgb), 1);
  }
}